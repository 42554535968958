#main-logo {
  position: absolute;
  top: 6vh;
  left: 6vw;
  top: calc(var(--vh, 1vh) * 6);
  left: calc(var(--vw, 1vw) * 6);
  z-index: 3;
}

/* SMALL SCREEN ONLY */
@media (min-width: 992px) and (max-width: 1440px) {
  #main-logo {
    top: calc(var(--vh, 1vh) * 5);
    left: calc(var(--vw, 1vw) * 5);
    img {
      max-height: 200px;
      max-width: 200px;
    }
  }
}

@media screen and (max-width: 769px) {
  #main-logo {
    top: 20px;
    left: 10px;
    img {
      max-height: 100px;
      max-width: 100px;
    }
  }
}
