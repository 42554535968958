@import '../../assets/sass/styles.scss';

.Rules {
  width: 100vw;
  height: 100vh;
  // position: absolute;
  display: flex;
  align-items: center;
  // overflow-y:auto;
  flex-direction: column;
  background-image: url('../../assets/images/flavor_background_alt.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $alternate;
  background-size: cover;
  justify-content: center;
  #main-logo {
    position: fixed;
  }
  div {
    max-width: 45vw;
    max-width: calc(var(--vw, 1vw) * 45);
    z-index: 3;
    max-height: 60vh;
    max-height: calc(var(--vh, 1vh) * 60);
    overflow-y: auto;
    color: black;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f525;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00000025;
      border-radius: 10px;
    }
    > ol {
      padding: 20px;
    }
  }
  h2 {
    color: $third;
    font-size: 3rem;
    font-weight: 700;
    margin: 3vh 0;
  }
  .chips-bowl {
    position: absolute;
    width: 200px;
    top: 28vh;
    right: 5vw;
  }
  .chips-1 {
    position: fixed;
    bottom: 60px;
    left: 30vw;
    z-index: 1;
  }
  .chips-2 {
    position: fixed;
    top: 60px;
    right: 30vw;
  }

  .chips-2-alt {
    position: fixed;
    top: 20vh;
    left: 5vw;
  }
}

ol {
  list-style: none;
  counter-reset: item;
  > li {
    counter-increment: item;
    display: table;
    &:before {
      color: $primary;
      font-weight: 600;
      padding-right: 10px;
      content: counters(item, '.') '.';
      display: table-cell;
    }
  }
  li {
    margin: 10px 0;
  }
  ol {
    padding-left: 20px;
  }
  ul li {
    margin-left: 20px;
  }
  .bold {
    font-weight: 600;
  }
  table {
    border-collapse: collapse;
    td,
    th {
      padding: 3px;
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
}

@media (min-width: 992px) and (max-width: 1440px) {
  .Rules {
    div {
      z-index: 3;
      max-width: 40vw;
      max-width: calc(var(--vw, 1vw) * 40);
    }

    .chips-1 {
      bottom: 20px;
      left: calc(var(--vw, 1vw) * 23);
      z-index: 1;
      max-width: 170px;
    }
    .chips-2 {
      top: 40px;
      right: calc(var(--vw, 1vw) * 23);
      max-width: 170px;
    }
  }
}

@media screen and (max-width: 769px) {
  .Rules {
    height: 100vh;
    position: static;
    // padding-top: 15vh;
    background-image: url('../../assets/images/flavor_background_alt_mobile.png');

    div {
      max-width: 95vw;
      max-height: 60vh;
      max-height: calc(var(--vh, 1vh) * 60);
    }
    .chips-bowl {
      display: none;
    }
    .chips-1 {
      display: none;
    }
    .chips-2 {
      display: none;
    }

    .chips-2-alt {
      display: none;
    }
    h2 {
      font-size: 2.5rem;
      margin: 1vh 0;
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
    font-size: 0.95rem;
    ol {
      padding-left: 5px;
    }
    ul li {
      margin-left: 5px;
    }
  }
}
