@import '../../assets/sass/styles.scss';

.Winners {
  width: 100vw;
  min-height: 100vh;
  // position: absolute;
  padding-top: 10vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  background-image: url('../../assets/images/flavor_background_alt.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $alternate;
  background-size: cover;
  .winners-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-bottom: 5vh;
    position: relative;
    z-index: 3;
    h2 {
      color: $third;
      font-size: 3rem;
      font-weight: 700;
    }
    span {
      color: $third;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  .winners-content {
    position: relative;
    display: block;
    z-index: 3;
    ul {
      z-index: 2;
      padding: 0 5vw;
      text-align: center;
      .winner-names {
        max-height: 50vh;
        max-height: calc(var(--vh, 1vh) * 50);
        overflow-y: auto;
        color: black;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f525;
          border-radius: 10px;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #00000025;
          border-radius: 10px;
        }
      }
    }
    .prize-name {
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 5px;
      color: #ed8a19;
      // max-width: 300px;
      &.main-prize-name {
        margin-top: 3rem;
      }
    }
    .columns {
      margin: 0;
      .is-narrow {
        // min-width: 446px;
      }
    }
  }
  .circles-wrapper {
    position: fixed;
    top: 25px;
    // z-index: 0;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
  .chips-bowl {
    position: absolute;
    width: 200px;
    top: 18vh;
    left: 2vw;
  }
  .chips-1 {
    position: absolute;
    bottom: 60px;
    left: 30vw;
    z-index: 1;
  }
  .chips-2 {
    position: absolute;
    top: 60px;
    right: 30vw;
  }
}

@media (min-width: 992px) and (max-width: 1440px) {
  .Winners {
    padding-top: 120px;
    .winners-header {
      z-index: 3;
    }
    .winners-content {
      z-index: 3;
    }
    .chips-1 {
      position: absolute;
      bottom: 20px;
      left: calc(var(--vw, 1vw) * 33);
      z-index: 1;
      max-width: 170px;
    }
    .chips-2 {
      position: absolute;
      top: 40px;
      right: calc(var(--vw, 1vw) * 25);
      max-width: 170px;
    }
  }
}

@media screen and (max-width: 769px) {
  .Winners {
    padding-top: 20vh;
    position: static;
    height: auto;
    background-image: url('../../assets/images/flavor_background_alt_mobile.png');
    .winners-header {
      text-align: center;
      span {
        max-width: 80vw;
      }
    }
    .winners-content {
      ul {
        padding-bottom: 20px;
      }
      .columns {
        .is-narrow {
          min-width: auto;
        }
      }
    }
    .chips-bowl {
      display: none;
    }
    .chips-1 {
      display: none;
    }
    .chips-2 {
      display: none;
    }
  }
}
